import React, {useState} from 'react';
import Admin from "./components/pages/Admin";
import Login from "./components/pages/Login";
import { Routes, Route } from "react-router-dom";
import { AppContext } from './Contexts/AppContext';


function App() {


  const [apiUrl, setapiUrl] = useState('https://api.sadaqahmadeeasy.com')
  const [auth, setAuth] = useState(localStorage.getItem('isloggedin') === null ? false : localStorage.getItem('isloggedin'))


  return (
   
    <AppContext.Provider value={{apiUrl, setapiUrl, auth, setAuth}}>

        <Routes>
          <Route path="/*" element={<Admin />} ></Route>
          <Route path="/login" element={<Login />} ></Route>  
         
        </Routes>
      </AppContext.Provider>
 
  );
}

export default App;
