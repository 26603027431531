import React from 'react';
import { NavLink } from 'react-router-dom';


const Nav = () => {

    // const [postparentclass, setPostparentclass] = useState('active')

    // const location = useLocation();

    // useEffect(() => {
      
    //     if(window.location.pathname.includes('post')){
    //         setPostparentclass('active')
    //     }else{
    //         setPostparentclass('active')
    //     }

    // }, [location]);



    return (
        <nav className="page-sidebar" id="sidebar">
        <div id="sidebar-collapse">
            <div className="admin-block d-flex">
                <div>
                    <img src="./assets/img/admin-avatar.png" alt='admin' width="45px" />
                </div>
                <div className="admin-info">
                    <div className="font-strong">James Brown</div><small>Administrator</small></div>
            </div>
            <ul className="side-menu metismenu post">
                <li>
                    <NavLink className="" to='/dashboard'><i className="sidebar-item-icon fa fa-th-large"></i>
                        <span className="nav-label">Dashboard</span>
                    </NavLink>
                </li>
                <li className="heading">CONTROLS</li>
                <li className="active">
                    <a href='' onClick={(e) => {e.preventDefault()}} >
                        <i className="sidebar-item-icon fa fa-bookmark"></i>
                        <span className="nav-label">Post</span><i className="fa fa-angle-left arrow"></i></a>
                    <ul className={`nav-2-level collapse in`}>
                        <li>
                            <NavLink to="/post/all">All Posts</NavLink>
                        </li>
                        <li>
                            <NavLink to="/post/pendings">Pendings</NavLink>
                        </li>
                        <li>
                        <NavLink to="/post/approved">Approved</NavLink>
                        </li>
                        <li>
                        <NavLink to="/post/archived">Archived</NavLink>
                        </li>

                    </ul>
                </li>
            </ul>
        </div>
    </nav>
    );
}

export default Nav;
