import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../Contexts/AppContext'
import '../../styles/Login.css'
import axios from 'axios'
const Login = () => {


  const navigate = useNavigate()

  const {apiUrl, setapiUrl, auth, setAuth} = useContext(AppContext)

  const [wronginput, setwronginput] = useState(false)


    const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const loginhandler = async () => {
       
        try{
          const {data} = await axios.post(
            `${apiUrl}/api/login`,
            { email, password },
            config
          )
            
          localStorage.setItem('token', JSON.stringify(data.token))
          localStorage.setItem('isloggedin', true)

          setwronginput(false)

          setAuth(true)

           navigate('/')

        }catch(err){
          console.log(err)
          setwronginput(true)
        }
                
          
  
      }

     


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()

        loginhandler()
        
      }


    return (
        <div className="content login-page">
        <div className="brand">
            <a className="link" href="index.html">sadaka made easy </a>
        </div>
        <form id="login-form" onSubmit={submitHandler}>
            <h2 className="login-title">Log in</h2>
            <div className="form-group">
                <div className="input-group-icon right">
                    <div className="input-icon"><i className="fa fa-envelope"></i></div>
                    <input className="form-control" type="text"                     
                       placeholder='Enter email'
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                     />
                </div>
            </div>
            <div className="form-group">
                <div className="input-group-icon right">
                    <div className="input-icon"><i className="fa fa-lock font-16"></i></div>
                    <input className="form-control" 
                      type='password'
                      placeholder='Enter password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>

           
           { wronginput && (
             <div className="alert alert-danger" role="alert">
             password wrong
           </div>
           )

           }
            <div className="form-group">
              <button className="btn btn-info btn-block" type="submit">Login</button>
            </div>
          
           
        </form>
    </div>
    );
}

export default Login;
