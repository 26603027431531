import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../Contexts/AppContext';
import axios from 'axios';

const DashBoard = () => {

  const [totalPending, setTotalPending] = useState('...')
  const [totalPost, setTotalPost] = useState('...')
  const [totalApproved, setTotalApproved] = useState('...')
  const [totalArchived, setTotalArchived] = useState('...')

  const isloggedin = localStorage.getItem('isloggedin')
  const { apiUrl } = useContext(AppContext)

  useEffect(() => {

    if (!isloggedin) return;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': JSON.parse(localStorage.getItem('token'))
      },
    }

    const fetchData = async () => {
      try {
        
        const pending = await axios.get(
          `${apiUrl}/api/project/pendings`, config
        )
        setTotalPending(pending.data.projects.length)

        const approved = await axios.get(
          `${apiUrl}/api/project/approved`, config
        )
        setTotalApproved(approved.data.projects.length)

        const archived = await axios.get(
          `${apiUrl}/api/project/archived`, config
        )
        setTotalArchived(archived.data.projects.length)

        const all = await axios.get(
          `${apiUrl}/api/project/all`, config
        )
        setTotalPost(all.data.projects.length)

       
     
     
     
     
      } catch (err) {

      }

    }

    fetchData()

  }, [apiUrl, isloggedin]);

    return (
        <div className="content-wrapper">

        <div className="page-content fade-in-up">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="ibox bg-success color-white widget-stat">
                <div className="ibox-body">
                  <h2 className="m-b-5 font-strong">{totalPost}</h2>
                  <div className="m-b-5">Total Post</div><i className="ti-shopping-cart widget-stat-icon"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="ibox bg-info color-white widget-stat">
                <div className="ibox-body">
                  <h2 className="m-b-5 font-strong">{totalPending}</h2>
                  <div className="m-b-5">Pending Post</div><i className="ti-bar-chart widget-stat-icon"></i>
                </div>
              </div>
            </div>
            
            <div className="col-lg-3 col-md-6">
              <div className="ibox bg-warning color-white widget-stat">
                <div className="ibox-body">
                  <h2 className="m-b-5 font-strong">{totalApproved}</h2>
                  <div className="m-b-5">Total Approve</div><i className="fa fa-money widget-stat-icon"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="ibox bg-secondary color-white widget-stat">
                <div className="ibox-body">
                  <h2 className="m-b-5 font-strong">{totalArchived}</h2>
                  <div className="m-b-5">Total Archived</div><i className="fa fa-money widget-stat-icon"></i>
                </div>
              </div>
            </div>


          </div>
        </div>

        <footer className="page-footer">
          <div className="font-13">2018 © <b>AdminCAST</b> - All rights reserved.</div>
          <a className="px-4" href="http://themeforest.net/item/adminca-responsive-bootstrap-4-3-angular-4-admin-dashboard-template/20912589" target="_blank">BUY PREMIUM</a>
          <div className="to-top"><i className="fa fa-angle-double-up"></i></div>
        </footer>
      </div>
    );
}

export default DashBoard;
