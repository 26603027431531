import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Contexts/AppContext';

const PageHeader = () => {

    const navigate = useNavigate()

    const {apiUrl, setapiUrl, auth, setAuth} = useContext(AppContext)


    const logoutHandler = () => {
        setAuth(false)
        localStorage.setItem('token', false)
        localStorage.setItem('isloggedin', false)
        navigate(`/login`)

    }




    return (
        <header className="header">
        <div className="page-brand">
            <a className="link" href="index.html">
                <span className="brand">Admin
                    <span className="brand-tip">CAST</span>
                </span>
                <span className="brand-mini">AC</span>
            </a>
        </div>
        <div className="flexbox flex-1">
        
            <ul className="nav navbar-toolbar">
                <li>
                    <a className="nav-link sidebar-toggler js-sidebar-toggler"><i className="ti-menu"></i></a>
                </li>

            </ul>
           
            <ul className="nav navbar-toolbar">

                <li className="dropdown dropdown-user">
                    <a className="nav-link dropdown-toggle link" data-toggle="dropdown">
                        <img src="./assets/img/admin-avatar.png" />
                        <span></span>Admin<i className="fa fa-angle-down m-l-5"></i></a>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#"><i className="fa fa-user"></i>Profile</a>
                        <a className="dropdown-item" href="#"><i className="fa fa-cog"></i>Settings</a>
                        <a className="dropdown-item" href="#"><i className="fa fa-support"></i>Support</a>
                        <li className="dropdown-divider"></li>
                        <a onClick={logoutHandler} className="dropdown-item" href="#"><i className="fa fa-power-off"></i>Logout</a>
                    </ul>
                </li>
            </ul>
        
        </div>
    </header>
    );
}

export default PageHeader;
