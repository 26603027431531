import { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../Contexts/AppContext';
import axios from 'axios';

const PostDetails = () => {

  const isloggedin = localStorage.getItem('isloggedin')
  const { apiUrl } = useContext(AppContext)

const params = useParams()
const navigate = useNavigate()

const goBack = () => {
    navigate(-1)
}

const [post, setpost] = useState([])
const [loading, setLoading] = useState(true)




useEffect(() => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'token': JSON.parse(localStorage.getItem('token'))
    },
  }

  if (!isloggedin) return;

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/project/adminview/${params.id}`, config
      )
    
      setpost(data.project)
      setLoading(false)
    } catch (err) {

    }

  }

  fetchData()

}, [apiUrl,isloggedin,  params.id]);




    return (

      
        <div className="content-wrapper">      
        <div className="page-heading d-flex mt-3 align-items-center">
            <button onClick={goBack} className="btn btn-primary" ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
          
          <h1 className="page-title ml-3">Post Details</h1>  
        </div>
        <div className="page-content fade-in-up">  
          <div className="ibox">  
            <div className="ibox-body">
          

            {loading && (
              <h1>Loading...</h1>
            )}

{post.length !== 0 && ( 


<div>
<div><b>Project Name: </b>{post.project_name}</div>
<div><b>Submitter Name or Org Name: </b>{post.name}</div>
<div><b>Address: </b>{post.address} </div>
<div><b>Contact and Payment: </b>{post.contact_details} </div>
<div><b>Submit Date: </b>  {new Date(post.created_at).toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} {new Date(post.created_at).toLocaleTimeString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true })} </div>
<div><b>Project Details: </b>{post.description} </div>
<div><b>Email: </b>{post.email} </div>
<div><b>Facebook: </b>{post.facebook} </div>
<div><b>Image link: </b>{post.img_link} </div>
<div><b>Linkedin: </b>{post.name} </div>
          
<div><b>Phone: </b>{post.phone} </div>
<div><b>Profession: </b>{post.profession} </div>
<div><b>Project Category: </b>{post.project_category} </div>
<div><b>Project Tag: </b>{post.project_tag} </div>
<div><b>Publish Date: </b>{post.publish_date} </div>
<div><b>Referred Id: </b>{post.referrer_id} </div>
<div><b>Slug: </b>{post.slug} </div>
<div><b>Social Link: </b>{post.social_link} </div>
<div><b>Status: </b>{post.status} </div>

</div>



) }

            

            </div>
          </div> 
        </div>   
      </div>
    );
}

export default PostDetails;
