import React, {useEffect} from 'react';
import PageHeader from '../PageHeader';
import Nav from '../Nav';
import DashBoard from './DashBoard';
import AllPost from './post/AllPost';
import Pendings from './post/Pendings';
import Approved from './post/Approved';
import Archived from './post/Archived.js';
import EditPost from './post/EditPost';
import PostDetails from './post/PostDetails';

import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

const Admin = () => {
    const navigate = useNavigate()
   

    const isloggedin = localStorage.getItem('isloggedin')
    

useEffect(() => {
    
if(isloggedin !== 'true'){
    navigate('/login')
}

},[isloggedin, navigate])





    return (
        <>
            <PageHeader />
            <Nav />      
            <Routes>
             
                <Route path='/' element={<Navigate to="dashboard"  />} ></Route>
                <Route path='post' element={<Navigate to="pendings"  />} ></Route>

                <Route path='dashboard' element={<DashBoard />} ></Route>

                <Route path='post/all' element={<AllPost />} ></Route>

                <Route path='post/pendings' element={<Pendings />} ></Route>
               
                <Route path='post/:id' element={<PostDetails />} ></Route>
                <Route path='post/edit/:id' element={<EditPost />} ></Route>

                <Route path='post/approved' element={<Approved />} ></Route>
                <Route path='post/archived' element={<Archived />} ></Route>
            </Routes>
            
        </>
    );
}

export default Admin;
