import { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../Contexts/AppContext';
import axios from 'axios';

const EditPost = () => {

  const [projectData, setProjectData] = useState({
    yourName: "",
    profession: "",
    project_category: "",
    fbID: "",
    lID: "",
    email: "",
    address: "",
    projectName: "",
    phone: "",
    projectDate: "",
    projectTag: "",
    projectDetails: "",
    projectSocial: "",
    projectRef: "",
    projectContact: "",
    projectImg: "",
    projectSlug: "",
    projectStatus: "",

  });

  const isloggedin = localStorage.getItem('isloggedin')
  const { apiUrl } = useContext(AppContext)

  const params = useParams()
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }


  const [loading, setLoading] = useState(true)

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'token': JSON.parse(localStorage.getItem('token'))
    },
  }

  useEffect(() => {

    const effectconfig = {
      headers: {
        'Content-Type': 'application/json',
        'token': JSON.parse(localStorage.getItem('token'))
      },
    }

    if (!isloggedin) return;
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/project/adminview/${params.id}`, effectconfig
        )
        setProjectData({
          yourName: data.project.name,
          profession: data.project.profession ? data.project.profession : "",
          project_category: data.project.project_category,
          fbID: data.project.facebook,
          lID: data.project.linkedin ? data.project.linkedin : "",
          email: data.project.email,
          address: data.project.address,
          projectName: data.project.project_name,
          phone: data.project.phone ? data.project.phone : "",
          projectDate: data.project.publish_date,
          projectTag: data.project.project_tag,
          projectDetails: data.project.description,
          projectSocial: data.project.social_link ? data.project.social_link : "",
          projectRef: data.project.referrer_id,
          projectContact: data.project.contact_details,
          projectImg: data.project.img_link,
          projectSlug: data.project.slug ? data.project.slug : "",
          projectStatus: data.project.status,

        });
        setLoading(false)
      } catch (err) {

      }
    }
    fetchData()
  }, [apiUrl, isloggedin, params.id]);

  const postProjectData = (e) => {

    const { name, value } = e.target;
    setProjectData({
      ...projectData,
      [name]: value
    });
  };

  const submitData = async (e) => {
    e.preventDefault();
    console.log(projectData, "projectData====");



    const {
      yourName,
      profession,
      phone,
      project_category,
      fbID,
      lID,
      email,
      address,
      projectName,
      projectDate,
      projectTag,
      projectDetails,
      projectSocial,
      projectRef,
      projectContact,
      projectImg,
      projectStatus,
      projectSlug
    } = projectData;


    if (
      (yourName &&
        fbID &&
        project_category &&
        email &&
        address &&
        projectName &&
        projectDate &&
        projectTag &&
        projectDetails &&
        projectRef &&
        projectContact &&
        projectImg &&
        projectStatus &&
        projectSlug
      )
    ) {

      let response;

      try {
        response = await axios.patch(`${apiUrl}/api/project/update/${params.id}`, {
          name: yourName,
          profession: profession,
          project_category: project_category,
          facebook: fbID,
          phone: phone,
          linkedin: lID,
          email: email,
          address: address,
          project_name: projectName,
          publish_date: projectDate,
          project_tag: projectTag,
          description: projectDetails,
          social_link: projectSocial,
          referrer_id: projectRef,
          contact_details: projectContact,
          img_link: projectImg,
          slug: projectSlug,
          status: projectStatus
        }, config)

      } catch (err) {

        console.log(err)
        alert('error happen')
      }



      if (response) {

        alert("Data edited");
      }
    } else {
      alert("Please fill all the required form");
    }
  };

  

  return (
    <div className="content-wrapper">
      <div className="page-heading d-flex mt-3 align-items-center">
        <button onClick={goBack} className="btn btn-primary" ><i className="fa fa-arrow-left" aria-hidden="true"></i></button>

        <h1 className="page-title ml-3">Post Edit</h1>
      </div>
      <div className="page-content fade-in-up">
        <div className="ibox">
          <div className="ibox-body">

          {loading && (
              <h1>Loading...</h1>
            )}

          {!loading && (

        
            <div>
              <form className="addProject__form" onSubmit={submitData}>
                <div className="row g-3">
                  <div className="col-sm-12">
                    <label htmlFor="yourName" className="form-label">
                      Org / Your name (Give your name if you fundraising personally)*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={projectData.yourName}
                      onChange={postProjectData}
                      name="yourName"
                      required
                    />

                  </div>

                  <div className="col-sm-6">
                    <label htmlFor="profession" className="form-label">
                      Profession
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={projectData.profession}
                      name="profession"
                      onChange={postProjectData}

                    />

                  </div>


                  <div className="col-sm-6">
                    <label htmlFor="fbID" className="form-label">
                      Facebook ID (link) *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      name="fbID"
                      placeholder="facebook.com/username"
                      value={projectData.fbID}
                      onChange={postProjectData}
                      required
                    />

                  </div>

                  <div className="col-sm-6">
                    <label htmlFor="lID" className="form-label">
                      Linkedin ID (link)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="linkedin.com/in/username"
                      name="lID"
                      value={projectData.lID}
                      onChange={postProjectData}
                    />

                  </div>

                  <div className="col-sm-6 col-12">
                    <label htmlFor="email" className="form-label">
                      Email *
                    </label>
                    <input
                      type="Email"
                      className="form-control"
                      id=""
                      placeholder="you@example.com"
                      name="email"
                      value={projectData.email}
                      onChange={postProjectData}
                      required
                    />

                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">
                      Address *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="1234, Banani, Dhaka"
                      name="address"
                      value={projectData.address}
                      onChange={postProjectData}
                      required
                    />

                  </div>


                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="" className="form-label">
                      Phone number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={projectData.phone}
                      onChange={postProjectData}
                      name="phone"
                      placeholder="enter phone number"

                    />

                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="" className="form-label">
                      project category *
                    </label>
                    <select className="form-control form-select"
                      value={projectData.project_category}
                      name="project_category"
                      onChange={postProjectData}
                      required
                    >
                      <option value="">please select one</option>
                      <option value="Madrasa">Madrasa</option>
                      <option value="Natural disaster">Natural disaster</option>
                      <option value="Tech">Tech</option>
                      <option value="Treatment">Treatment</option>
                      <option value="Regular">Regular</option>
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="projectName" className="form-label">
                      Project name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="projectName"
                      placeholder="যেমন, পরিবারের একমাত্র কর্তা ক্যান্সারে আক্রান্ত"
                      name="projectName"
                      value={projectData.projectName}
                      onChange={postProjectData}
                      required
                    />

                  </div>

                  <div className="col-6">
                    <label htmlFor="projectDate" className="form-label">
                      Date of publish *
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="projectDate"
                      name="projectDate"
                      value={projectData.projectDate}

                      onChange={postProjectData}
                      required
                    />
                  </div>

                  <div className="col-6">
                    <label htmlFor="projectTag" className="form-label">
                      Project tag *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="projectTag"
                      placeholder="Add max two tags separetad by comma"
                      name="projectTag"
                      value={projectData.projectTag}
                      onChange={postProjectData}
                      required
                    />

                  </div>



                  <div className="col-12">
                    <div className="">
                      <label htmlFor="floatingTextarea1" className="form-label">
                        Project details *
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Please provide a TRUE story"
                        id="floatingTextarea1"
                        name="projectDetails"
                        value={projectData.projectDetails}
                        onChange={postProjectData}
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-6">
                    <label htmlFor="projectSocial" className="form-label">
                      Project social link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="projectSocial"
                      placeholder="Add facebook post link (if any)"
                      name="projectSocial"
                      value={projectData.projectSocial}
                      onChange={postProjectData}
                    />
                  </div>

                  <div className="col-6">
                    <label htmlFor="projectRef" className="form-label">
                      Referrer ID link *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="projectRef"
                      placeholder="Facebook/Linkedin/Any ID link of the referrer"
                      name="projectRef"
                      value={projectData.projectRef}
                      onChange={postProjectData}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <div className="">
                      <label htmlFor="floatingTextarea2" className="form-label">
                        Contact and send money details *
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Follow one of our project details page"
                        id="floatingTextarea2"
                        name="projectContact"
                        value={projectData.projectContact}
                        onChange={postProjectData}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="projectImg" className="form-label">
                      Project img *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="projectImg"
                      placeholder="Place google drive link or any public link of the image"
                      name="projectImg"
                      value={projectData.projectImg}
                      onChange={postProjectData}
                      required
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="" className="form-label">
                      Project Slug * ( must be unique)
                    </label>
                    <input
                      type="text"
                      className="form-control"

                      placeholder="slug must"
                      name="projectSlug"
                      value={projectData.projectSlug}
                      onChange={postProjectData}
                      required
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="" className="form-label">
                      project status *
                    </label>
                    <select className="form-control form-select"
                      value={projectData.projectStatus}
                      name="projectStatus"
                      onChange={postProjectData}
                      required
                    >
                      <option value="pending">pending</option>
                      <option value="approved">approved</option>
                      <option value="archived">archived</option>
                    </select>
                  </div>

                </div>

                <button
                  type="submit"
                  className="w-100 btn btn-success btn-lg"
                //  onSubmit={submitData}
                >
                  Confirm submit
                </button>

              </form>
            </div>
  )}


          </div>
        </div>
      </div>
    </div>

  );
}

export default EditPost;
