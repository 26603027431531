import { useNavigate } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../../Contexts/AppContext';
import axios from 'axios';

const AllPost = () => {

  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)

  const isloggedin = localStorage.getItem('isloggedin')
  const { apiUrl } = useContext(AppContext)

  const navigate = useNavigate()

  const gotoPost = (id) => {
    navigate(`/post/${id}`)
  }
  const editPost = (id) => {
    navigate(`/post/edit/${id}`)
  }




  useEffect(() => {

    if (!isloggedin) return;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': JSON.parse(localStorage.getItem('token'))
      },
    }

    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/project/all`, config
        )
        setPosts(data.projects)
        setLoading(false)
      } catch (err) {

      }

    }

    fetchData()

  }, [apiUrl, isloggedin]);




  return (
    <div className="content-wrapper">

      <div className="page-heading">
        <h1 className="page-title">All Posts</h1>

      </div>
      <div className="page-content fade-in-up">

        <div className="ibox">

          <div className="ibox-body">
            {loading && (
              <h1>Loading...</h1>
            )}

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>

                    <th>Submitter Name</th>
                    <th>Submit Date</th>
                    <th>Project name</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>


                  {posts && posts.length > 0 && (


                    posts.map(item => (

                      <tr key={item.id}>
                        <td>{item.name.substring(0,100)}...</td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                          <br />
                          {new Date(item.created_at).toLocaleTimeString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true })}
                          </td>
                        <td>{item.project_name.substring(0,100)}...</td>
                        <td>{item.status}</td>
                      
                        <td>
                          <button  onClick={() => editPost(item.id)} className="btn btn-default btn-xs m-r-5" data-toggle="tooltip" data-original-title="Edit"><i className="fa fa-pencil font-14"></i></button>
                      
                          <button  onClick={() => gotoPost(item.id)} className="btn btn-default btn-xs" data-toggle="tooltip" data-original-title="view"><i className="fa fa-eye font-14"></i></button>
                        </td>
                      </tr>

                    ))



                  )}


                
                 


                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>


    </div>
  );
}

export default AllPost;
